import React from "react";
import "./Testimonials.css";
import { testimonialsData } from "../../data/testimonialsData";
import { useState } from "react";
import leftArrow from "../../assets/leftArrow.png";
import rightArrow from "../../assets/rightArrow.png";
import { motion } from "framer-motion";
export default function Testimonials() {
  const transition = {type:'spring',duration:'3'}
  const [select, setSelected] = useState(0);
  const tlength = testimonialsData.length;
  console.log(tlength)
  return (
    <div className="testimonials" id="testimonials">
      <div className="left-t">
        <span>Testimonials</span>
        <span className="stroke-text">What they</span>
        <span> Say about us</span>
        <motion.span initial={{opacity:0,x:-100}} animate={{opacity:1,x:0}} exit={{opacity:0,x:100}} transition={transition} key={select}>{testimonialsData[select].review}</motion.span>
        <span>
          <span style={{ color: "var(--orange)" }}>
            {testimonialsData[select].name}
          </span>{" "}
          - {testimonialsData[select].status}
        </span>
      </div>
      <div className="right-t">
        <motion.div initial={{opacity:0,x:-100}} whileInView={{opacity:1,x:0}} transition={{...transition,duration:2}}></motion.div>
        <motion.div initial={{opacity:0,x:100}} whileInView={{opacity:1,x:0}} transition={{...transition,duration:2}}></motion.div>
        <motion.img src={testimonialsData[select].image} alt="" key={select} initial={{opacity:0,x:100}} animate={{opacity:1,x:0}}
        exit={{opacity:0,x:-100}} transition={transition} />
        <div className="arrows">
          <img
            onClick={() =>
              select === 0
                ? setSelected(tlength - 1)
                : setSelected((prev) => prev - 1)
            }
            src={leftArrow}
            alt=""
          />
          <img onClick={() =>
              select === tlength-1
                ? setSelected(0)
                : setSelected((prev) => prev + 1)
            } src={rightArrow} alt="" />
        </div>
      </div>
    </div>
  );
}
