import web from '../assets/web.png';
import app from '../assets/ap.png';
import wordpress from '../assets/wordpress.png';
import AI from '../assets/AI.png'
export const programsData = [
  {
    image: (
<img src={app}></img>
    ),
    heading: "App Development",
    details:"At our Software house , you can get your mobile app done from our experts for your work",
  },
  {
    image: (
 <img src={web}></img>
    ),
    heading: "Web development",
    details:
      "At our Software house , you can get your Web app done from our experts for your work",
  },
  {
    image: (
<img src={wordpress}></img>
    ),
    heading: "Wordpress",
    details:
      "At our Software house , you can get your wordpress site done from our experts for your work",
  },
  {
    image: (
      <img src={AI}></img>
    ),
    heading: "Artificial Inteligence",
    details:
      "At our Software house , you can get your AI done from our experts for your work",
  },
];
