import web from '../assets/web.png';
import app from '../assets/ap.png';
import wordpress from '../assets/wordpress.png';
export const plansData = [
  {
    icon: (
      <img src={web}></img>
    ),
    name: "BASIC WEBSITE",
    price: "25",
    features: [
      "2 free of cost alteration",
      "Responsive",
      "Access to database",
    ],
  },
  {
    icon: (
      <img src={wordpress}></img>
    ),
    name: "WORDPRESS SITE",
    price: "30",
    features: [
      "5 free of cost alterations",
      "Integrated Plugings",
      "Delivery in three phases",
    ],
  },
  {
    icon: (
      <img src={app}></img>
    ),
    name: "MOBILE APP",
    price: "45",
    features: [
      "8 free of cost alterations",
      "Repache integrated",
      "Secure app deployment",
    ],
  },
];
