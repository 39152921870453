import image1 from "../assets/image1-t.jpg";
import image2 from "../assets/image2-t.jpg";
import image3 from "../assets/image3-t.jpg";

export const testimonialsData = [
  {
    image: image1,
    review:
      "I made the right choice by choosing this software house and by choosing the right plan and program I already achieved my ideal dynamic website!",
    name: 'MATHEW HENDRICKSON',
    status : 'DEVELOPER'
  },
  {
    image: image2,
    review: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Commodi ipsam, ab itaque nam perferendis impedit sint ',
    name: 'JOHN KEVIN',
    status: 'CLIENT'
  },
  {
    image : image3,
    review:' Lorem ipsum dolor sit, amet consectetur adipisicing elit. Minima aspernatur quod voluptatem',
    name: 'HELLA',
    status: "BUSINESSWOMEN"
  }
];
