import React from "react";
import "./Plans.css";
import {plansData} from '../../data/plansData';
import whiteTick from '../../assets/whiteTick.png'
export default function Plans() {
  return (
    <div className="plans-container" id="plans">
      <div className="blur plans-blur-1"></div>
      <div className="blur plans-blur-2"></div>

      <div className="programs-header" style={{ gap: "2rem" }}>
        <span className="stroke-text">Ready to Boast up</span>
        <span>your Business</span>
        <span className="stroke-text">with us</span>
      </div>
      {/* plans Card  */}
      <div className="plans">
        {plansData.map((plan, i) => (
          <div className="plan" key={i}>
            {plan.icon}
            <span>{plan.name}</span>
            <span>$ {plan.price}</span>
            <div className="features">
                {plan.features.map((feature,i)=>(
               <div className="feature">
                  <img src={whiteTick} alt="" />
                  <span key={i}>{feature}</span>
               </div>   
                ))}
            </div>
            <div>
              <span>See more</span>
            </div>
            <button className="btn">Acess Now</button>
          </div>
        ))}
      </div>
    </div>
  );
}
